// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

// IMPORT BARBA
import barba from '@barba/core';
import barbaCss from '@barba/css';

// IMPORT OWL CAROUSEL
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';


// On page load do
document.addEventListener("turbolinks:load", () => {

  //barba page transitions
  barba.use(barbaCss)

  barba.init({
  	transitions: [
  		{
  			name: "fade",

        leave() {
          console.log('out')
        },

        enter() {
          console.log('in')
        },

  			beforeEnter() {

  				// reset page positioning
  				window.scrollTo({
  					top: 0,
  					behavior: "auto"
  				});

          loadCarousel()
          boldFirst()
          quantitySelector()
          loadFilters()

  			},

        afterEnter() {

        },

        after() {


        }

  		},

      {
  			name: "none",

        leave() {
          // create your stunning leave animation here
          console.log('Filter Change')
        },

        enter() {
          // create your amazing enter animation here
          console.log('Filter Change')
        },

        beforeEnter() {
          loadCarousel()
        },

        from: {
          namespace: [
            'products', 'news'
          ]
        },

        to: {
          namespace: [
            'products', 'news'
          ]
        },
  		}
  	],

    views: [
      {
        namespace: "checkout",

        afterEnter() {
          var stripe = Stripe($('#stripe-key').html())
          var elements = stripe.elements({clientSecret: $("#data-holder").data("intent")})

          var style = {
            base: {
              color: '#32325d',
              fontFamily: '"Graphik Regular", Helvetica, sans-serif',
              fontSmoothing: 'antialiased',
              fontSize: '1em',
              backgroundColor: 'white',
              padding: '10px',
              '::placeholder': {
                color: '#aab7c4'
              }
            },
            invalid: {
              color: '#fa755a',
              iconColor: '#fa755a'
            }
          };

          var payments = elements.create('payment', {
            fields: {
              billingDetails: 'never'
            }
          })
          payments.mount("#payments")

          $("#submit").click(function(e){
            e.preventDefault();

            if (validateForm()) {
              var address = {
                line1: $("#order_address_1").val(),
                line2: $("#order_address_2").val(),
                postal_code: $("#order_post_code").val(),
                city: $("#order_city").val(),
                state: $("#order_city").val(),
                country: $("#order_country").val()
              }

              stripe.confirmPayment({
                elements,
                confirmParams: {
                  payment_method_data: {
                    billing_details: {
                      name: $("#order_first_name").val() + " " + $("#order_last_name").val(),
                      email: $("#order_email").val(),
                      phone: $("#order_phone").val(),
                      address: address
                    }
                  },
                  shipping: {
                    name: $("#order_first_name").val() + " " + $("#order_last_name").val(),
                    address: address
                  },
                  return_url: $("#data-holder").data("returnUrl"),
                },
              }).then(function(result) {
                if (result.error) {
                  if (result.error.code === 'payment_intent_unexpected_state') {
                    // Handle the specific error for a succeeded PaymentIntent
                    $('#card-errors').html("This order has already been successfully processed. If you have not received a confirmation email, please contact us.");
                  } else {
                    // Handle other Stripe errors
                    $('#card-errors').html(result.error.message);
                  }
                }
              });
            }
          })
        }
      },

      {
        namespace: "news",

        beforeEnter() {
          // loadAnnouncements()
        }
      },

      {
        namespace: "herbal",

        beforeEnter() {

          $('.herb').click(function(){
            $(this).find('.body').slideToggle()
            $(this).find('.title span').toggle()
          })

        }
      },

      {
        namespace: "products",

        beforeEnter() {
          // Search bar
          const searchInput = document.forms['searchInput'].querySelector('input');
          const blogTitles = document.querySelectorAll('.product .productName');
          const blogPost = document.querySelectorAll('.product')
          var i

          // Listen for keyup on the search form input
          searchInput.addEventListener('keyup', function(e){
            // Force input to lowercase and assign to variable
          	const term = e.target.value.toLowerCase();
            // Loop through every blog title and check for text similar
          	for (i = 0; i < blogTitles.length; i++) {
          		var title = blogTitles[i].textContent
          		if(title.toLowerCase().indexOf(term) != -1) {
          			blogPost[i].style.display = 'block'
          		}
          		else {
          			blogPost[i].style.display = 'none'
          		}
          	}
          })
        }
      }
    ]
  })

  // SITE WIDE JS
  function validateForm() {
    let line1 = $("#order_address_1").val()
    let line2 = $("#order_address_2").val()
    let postal_code = $("#order_post_code").val()
    let city = $("#order_city").val()
    let state = $("#order_city").val()
    let country = $("#order_country").val()

    let firstName = $("#order_first_name").val()
    let lastName = $("#order_last_name").val()
    let phone = $("#order_phone").val()
    let email = $("#order_email").val()

    $('#card-errors').html("")

    if (line1.length == 0 || postal_code.length == 0 || city.length == 0 || state.length == 0 || country.length == 0 || firstName.length == 0 || lastName.length == 0 || phone.length == 0 || email.length == 0) {
      $('#card-errors').html("Please complete your name, contact and address information!")
      alert("Please complete your name, contact and address information!");

      return false;
    }

    return true;
  }

  // Mobile Menu Show
  var mobileHeaderVisible = false
  $('#mobileMenu').click(mobileHeaderToggle)
  $('header .links a').click(mobileHeaderToggle)
  console.log('Loaded')

  function mobileHeaderToggle() {
    if (window.innerWidth < 950) {
      if (mobileHeaderVisible == false) {
        $('header .links').css('transform', 'translateY(0px)')
        mobileHeaderVisible = true
      } else {
        $('header .links').css('transform', 'translateY(-368px)')
        mobileHeaderVisible = false
      }
    }
  }

  // Remove flash message
  $('.flashSuccess').delay('1500').slideUp('fast')


  // drawBorders()
  // function drawBorders() {
  //   $('section .product').not('.hide').each(function(index) {
  //     console.log(index)
  //   })
  // }


  // Add quantity selector buttons
  quantitySelector()
  function quantitySelector() {
    $("form div.integer").prepend('<div class="dec button">-</div>');
    $("form div.integer").append('<div class="inc button">+</div>');
    $(".button").click(function() {
      var button = $(this);
      var oldValue = button.parent().find("input").val();
      if (button.text() == "+") {
    	  var newVal = parseFloat(oldValue) + 1;
    	} else {
       // Don't allow decrementing below zero
        if (oldValue > 0) {
          var newVal = parseFloat(oldValue) - 1;
        } else {
          newVal = 0;
        }
      }
      button.parent().find("input").val(newVal);
    });
  }

  loadCarousel()
  // Owl carousel
  function loadCarousel() {
    $(document).ready(function(){

      $('.owl-carousel').owlCarousel({
        loop: true,
        center: false,
        margin: 0,
        nav: false,
        dots: true,
        autoplay: true,
        autoplayHoverPause: true,
        autoplayTimeout: 3000,
        items: 1
      });

      console.log('Load Carousel')

    })
  }

  // Bold first word in product title
  boldFirst()
  function boldFirst() {
    $('h1.title').each(function(){
      var me = $(this);
      me.html( me.text().replace(/(^\w+)/,'<span>$1</span>') );
    });
  }

  // Load news stories
  // loadAnnouncements()
  function loadAnnouncements() {
    // Load in announcements, default to show the first one
    $('.newsPostView .newsPost').each(function(index) {
      if (index == 0) {
        $(this).removeClass('hide')
      } else {
        $(this).addClass('hide')
      }
    })
    // Highlight selected announcement
    $('.newsPostsList .newsPost').each(function(index) {
      if (index == 0) {
        $(this).addClass('selected')
      } else {
        $(this).removeClass('selected')
      }
    })
    // Show the selected announcement
    $('.newsPostsList .newsPost').click(function() {
      var selectedAnnouncement = $(this).find('h3').html()
      $('.newsPostView .newsPost').each(function() {
        if ($(this).find('h2').html() == selectedAnnouncement) {
          $(this).removeClass('hide')
          $(this).addClass('selected')
        } else {
          $(this).addClass('hide')
          $(this).removeClass('selected')
        }
      })
    })
    // Highlight selected announcement
    $('.newsPostsList .newsPost').click(function() {
      $('.newsPostsList .newsPost').each(function(index) {
        $(this).removeClass('selected')
      })
      $(this).addClass('selected')
    })
  }

  drawBorders()
  function drawBorders() {
    $('section .product').each(function() {
      $(this).removeClass('fourth')
    })
    $("section.sortable .product.isVisible").filter(function(index, element){
      return (index + 1) && ((index + 1) % 4 === 0);
    }).addClass("fourth");
  }


  loadFilters()
  function loadFilters() {

    // Filter function
    $('.filter span').click(function() {
      // Remove the underline
      $('.filter span').each(function() {
        $(this).removeClass('selected')
      })
      // Add underline, identify product
      $(this).addClass('selected')
      var selectedSortOption = $(this).html()

      $('.product').each(function() {
        if ($(this).hasClass(selectedSortOption) == false) {
          $(this).addClass('hide')
          $(this).removeClass('isVisible')
        } else {
          $(this).removeClass('hide')
          $(this).addClass('isVisible')
        }
      })

      drawBorders()
    })

    // Sort by name
    $('span.byName').click(function() {
      deselect()
      $(this).addClass('selected')
      var sortParameter
      sortParameter = ".product .productName"
      sortListDir(sortParameter)
    })

    // Sort by price
    $('span.byPrice').click(function() {
      deselect()
      $(this).addClass('selected')
      var sortParameter
      sortParameter = ".product .productPrice"
      sortListPrice(sortParameter)
    })

    function deselect() {
      $('.sort span').each(function(){
        $(this).removeClass('selected')
      })
    }

    // Sort list function
    function sortListDir(sortParameter) {
      console.log('Sort')
      var list, i, switching, b, c, shouldSwitch, dir, switchcount = 0;
      list = document.querySelector("section.sortable")
      switching = true;
      // Set the sorting direction to ascending:
      dir = "asc";
      // Make a loop that will continue until no switching has been done:
      while (switching) {
        // Start by saying: no switching is done:
        switching = false;
        b = list.querySelectorAll(".product")
        c = list.querySelectorAll(sortParameter)
        // Loop through all list-items:
        for (i = 0; i < (b.length - 1); i++) {
          // Start by saying there should be no switching:
          shouldSwitch = false;
          /* Check if the next item should switch place with the current item,
          based on the sorting direction (asc or desc): */
          if (dir == "asc") {
            if (c[i].innerHTML.toLowerCase() > c[i + 1].innerHTML.toLowerCase()) {
              /* If next item is alphabetically lower than current item,
              mark as a switch and break the loop: */
              shouldSwitch = true;
              break;
            }
          } else if (dir == "desc") {
            if (c[i].innerHTML.toLowerCase() < c[i + 1].innerHTML.toLowerCase()) {
              /* If next item is alphabetically higher than current item,
              mark as a switch and break the loop: */
              shouldSwitch= true;
              break;
            }
          }
        }
        if (shouldSwitch) {
          /* If a switch has been marked, make the switch
          and mark that a switch has been done: */
          b[i].parentNode.insertBefore(b[i + 1], b[i]);
          switching = true;
          // Each time a switch is done, increase switchcount by 1:
          switchcount ++;
        } else {
          /* If no switching has been done AND the direction is "asc",
          set the direction to "desc" and run the while loop again. */
          if (switchcount == 0 && dir == "asc") {
            dir = "desc";
            switching = true;
          }
        }
      }

      drawBorders()
    }




    // Sort list function
    function sortListPrice(sortParameter) {
      console.log('Sort')
      var list, i, switching, b, c, shouldSwitch, dir, switchcount = 0;
      list = document.querySelector("section.sortable")
      switching = true;
      // Set the sorting direction to ascending:
      dir = "asc";
      // Make a loop that will continue until no switching has been done:
      while (switching) {
        // Start by saying: no switching is done:
        switching = false;
        b = list.querySelectorAll(".product")
        c = list.querySelectorAll(sortParameter)
        // Loop through all list-items:
        for (i = 0; i < (b.length - 1); i++) {
          // Start by saying there should be no switching:
          shouldSwitch = false;
          /* Check if the next item should switch place with the current item,
          based on the sorting direction (asc or desc): */
          if (dir == "asc") {
            if (parseFloat(c[i].innerHTML) > parseFloat(c[i + 1].innerHTML)) {
              /* If next item is alphabetically lower than current item,
              mark as a switch and break the loop: */
              shouldSwitch = true;
              break;
            }
          } else if (dir == "desc") {
            if (parseFloat(c[i].innerHTML) < parseFloat(c[i + 1].innerHTML)) {
              /* If next item is alphabetically higher than current item,
              mark as a switch and break the loop: */
              shouldSwitch= true;
              break;
            }
          }
        }
        if (shouldSwitch) {
          /* If a switch has been marked, make the switch
          and mark that a switch has been done: */
          b[i].parentNode.insertBefore(b[i + 1], b[i]);
          switching = true;
          // Each time a switch is done, increase switchcount by 1:
          switchcount ++;
        } else {
          /* If no switching has been done AND the direction is "asc",
          set the direction to "desc" and run the while loop again. */
          if (switchcount == 0 && dir == "asc") {
            dir = "desc";
            switching = true;
          }
        }
      }

      drawBorders()
    }
  }








})